<template>
    <div class="ClientStoriesProject CSP" id="ClientStoriesProjectPage">
        <section class="CSPHeader CSP-section" id="CSPHeader">
            <div class="CSPHeader-container">
                <div class="CSPHeader-intro">
                    <div class="CSPHeader-logo">
                        <figure :class="`CSPHeader-logo_${this.currentProject.key}`">
                            <img :alt="this.currentProject.name" :src="`${this.publicPath}img/clients/${this.currentProject.key}.png`" />
                        </figure>
                    </div>
                    <div class="CSPHeader-services">
                        <ul v-if="this.currentProject.story.services && this.currentProject.story.services.length > 0">
                            <li :key="services.index" v-for="services in this.currentProject.story.services">{{services}}</li>
                        </ul>
                    </div>
                </div>
                <div class="CSPHeader-challenge">
                    <h3 class="CSPHeader-title title">Our challenge</h3>
                    <div class="CSPHeader-text CSP-text" v-html="this.currentProject.story.challenge"></div>
                    <p style="text-align:right" v-if="this.currentProject.story.author.name">{{this.currentProject.story.author.name}}, {{this.currentProject.story.author.role}}.</p>
                </div>
            </div>
        </section>
        <section class="CSPRose CSP-section" id="CSPRose" v-if="this.currentProject.story.rose">
            <div class="CSPRose-container">
                <div class="CSPRose-content">
                    <h3 class="CSPRose-title title">What went well</h3>
                    <div class="CSPRose-text CSP-text" v-html="this.currentProject.story.rose"></div>
                </div>
                <div class="CSPRose-picture" v-if="this.currentProject.story.icon">
                    <figure class="CSPRose-picture-icon">
                        <svg class="CSPRose-picture-svg">
                            <use v-bind:xlink:href="`#${this.currentProject.story.icon}`"></use>
                        </svg>
                    </figure>
                </div>
            </div>
        </section>
        <section class="CSPThornBanana CSP-section" id="CSPThornBanana">
            <div class="CSPThornBanana-container">
                <div class="CSPThorn" v-if="this.currentProject.story.thorn">
                    <h3 class="CSPThorn-title title">What didn't go well</h3>
                    <div class="CSPThorn-text CSP-text" v-html="this.currentProject.story.thorn"></div>
                </div>
            </div>
            <div class="CSPThornBanana-container">
                <div class="CSPBanana" v-if="this.currentProject.story.banana">
                    <h3 class="CSPBanana-title title">What's suprised us</h3>
                    <div class="CSPBanana-text CSP-text" v-html="this.currentProject.story.banana"></div>
                </div>
            </div>
        </section>
        <section class="CSPResult CSP-section" id="CSPResult">
            <div class="CSPResult-results" v-if="this.currentProject.story.results">
                <h3 class="CSPResult-title title">Results</h3>
                <div class="CSPResult-text CSP-text" v-html="this.currentProject.story.results"></div>
            </div>
            <div class="CSPResult-stats" v-if="this.currentProject.story.stats">
                <div v-html="this.currentProject.story.stats"></div>
            </div>
            <div class="CSPResult-gradient"></div>
            <div :style="this.currentProject.story.pictures[0]?`background-image: url('${this.publicPath}img/clientsStories/${this.currentProject.story.pictures[0]}')`:''" class="CSPResult-bg"></div>
        </section>
        <ClientStoriesNavigation />
    </div>
</template>

<script>
    import router from '@/router';
    import store from '@/store';

    export default {
        name: 'ClientStoriesProject',
        metaInfo() {
            const title = `Client Stories: ${this.currentProject.name}`;
            return { title };
        },
        data() {
            return {
                publicPath: process.env.BASE_URL,
                ourClients: store.state.ourClients
            };
        },
        /*
        afterRouteEnter (to, from, next) {
            if (to.currentProject === undefined) {
                //next(false);
                router.push({ name: 'Home' });
            }
            next();
        },
        */
        components: {
            'ClientStoriesNavigation': () => import('@/components/ClientStoriesNavigation')
        },
        computed: {
            currentProject() {
                const currentProject = this.ourClients.filter(client => client.key === this.$route.params.client && client.story.comingSoon !== true)[0];
                if (currentProject === undefined) {
                    router.push({ name: 'Home' });
                    return '';
                }
                return currentProject;
            }
        }
    };
</script>
